import React from "react";
import { Box, Typography } from "@mui/material";
import { useMediaQuery, mediaQuery } from "../hooks/useMediaQuery";
import { getFooterHeight } from "../components/Footer";

function ContactCompleted() {
  const isSp = useMediaQuery(mediaQuery.sp);
  const footerHeight = getFooterHeight(isSp);
  const minHeight = window.innerHeight - footerHeight;
  return (
    <Box className="bg-gray-100 flex-col justify-between" minHeight={minHeight}>
      <div className="flex justify-center mb-8">
        <div className="text-3xl font-bold mt-8">お問い合わせ完了</div>
      </div>
      <Typography className="pt-4 px-4" variant="body1" align="center">
        お問い合わせ頂きありがとうございました。
        <br />
        スタッフよりなるべく早くお返事いたしますので、今しばらくお待ちください。
      </Typography>
      <div className="flex justify-center py-16">
        <a
          href="/"
          className="bg-btn text-white font-bold py-3 px-8 rounded-full"
        >
          トップページに戻る
        </a>
      </div>
    </Box>
  );
}

export default ContactCompleted;
