import React from "react";
import { useMediaQuery, mediaQuery } from "./hooks/useMediaQuery";
import "./App.css";

function App() {
  const isSp = useMediaQuery(mediaQuery.sp);

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col justify-between">
      <div>
        {isSp ? (
          <div>
            {/* Hero Section for Mobile */}
            <div className="custom-bg w-screen h-[1520px] flex flex-col items-center text-center relative">
              <div className="w-full max-w-2xl">
                <img
                  src="./img/thumbnail.png"
                  alt="thumbnail"
                  className="clip-trapezoid-sp top-0 left-0 w-[100%]"
                />
                <img
                  src="./img/phrase_sp.png"
                  alt="phrase"
                  className="absolute top-4 right-8 w-[30%] h-auto"
                />
                <div className="flex justify-center mt-8">
                  <img
                    src="./img/issuepanda.png"
                    alt="issuepanda"
                    className="w-[80%] h-auto"
                  />
                </div>
                <div className="flex justify-center">
                  <img
                    src="./img/description.png"
                    alt="description"
                    className="w-[70%] h-auto"
                  />
                </div>
                <div className="flex justify-center items-center mt-4">
                  <img
                    src="./img/register.png"
                    alt="register"
                    className="w-[40%] h-auto"
                  />
                </div>
                <div className="flex justify-center items-center mt-8">
                  <img
                    src="./img/continue.png"
                    alt="continue"
                    className="w-[60%] h-auto"
                  />
                </div>

                <div className="flex justify-center items-center mt-8">
                  <img
                    src="./img/settlement_options.png"
                    alt="settlement_options"
                    className="w-[80%] h-auto"
                  />
                </div>
                <div className="flex justify-center items-center mt-8">
                  <img
                    src="./img/advantages.png"
                    alt="advantages"
                    className="w-[80%] h-auto"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center relative mt-[-48px] z-100">
              <a
                href="/contact"
                className="bg-btn text-white font-bold py-3 px-8 rounded-full"
              >
                お問い合わせはこちら
              </a>
            </div>
            <div className="bg-white py-16">
              <div className="flex justify-center">
                <div className="text-3xl font-bold mb-8 px-8">
                  ISSUE PANDAを利用すると様々な施策を行うことができます！
                </div>
              </div>
              <div className="flex justify-center">
                <img
                  src="./img/point1.png"
                  alt="point1"
                  className="w-auto h-[400px]"
                />
              </div>
              <div className="flex justify-center">
                <img
                  src="./img/point2.png"
                  alt="point2"
                  className="w-auto h-[400px]"
                />
              </div>
            </div>
            <div className="bg-white py-16">
              <div className="flex justify-center">
                <div className="text-3xl font-bold mb-8">導入までの流れ</div>
              </div>
              <div className="flex justify-center">
                <img
                  src="./img/installation.jpg"
                  alt="installation"
                  className="w-auto h-[400px]"
                />
              </div>
              <div className="flex justify-center mt-12 px-8">
                <div className="text-3xl font-bold">
                  導入決定から約30日目で運用開始となります
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <a
                href="/contact"
                className="bg-btn text-white font-bold py-3 px-8 rounded-full"
              >
                お問い合わせはこちら
              </a>
            </div>
            <div className="flex justify-center mt-12">
              <div className="text-3xl font-bold">注意事項</div>
            </div>
            <div className="flex justify-center mt-4 mb-16">
              <div className="text-sm w-[80%] h-auto">
                お問い合わせいただきました、ご担当者様/店舗等の情報は、ISSUEPANDAのご提供に関する業務にのみ活用させていただきます
                詳しくは当社のプライバシーポリシーをご覧くださいませ。
                <br />
                ご導入後のお客様決済には、各種決済手段が取り決める手数料がかかります。
                決済手段ごとの決済手数料に関しては、担当営業にお問い合わせください。
                <br />
                ご導入に際するスケジュール等は、お店の運営状況により異なります。
                お問い合わせの上、ご調整させてくださいませ。
              </div>
            </div>
          </div>
        ) : (
          <div>
            {/* Hero Section for Desktop */}
            <div className="custom-bg w-screen h-[1560px] flex flex-col items-center text-center relative">
              <div className="w-full max-w-2xl">
                <img
                  src="./img/phrase.png"
                  alt="phrase"
                  className="absolute top-24 left-16 w-auto h-[168px]"
                />
                <img
                  src="./img/thumbnail.png"
                  alt="thumbnail"
                  className="clip-trapezoid absolute top-0 right-0 w-[60%]"
                />
                <img
                  src="./img/issuepanda.png"
                  alt="issuepanda"
                  className="absolute top-[296px] left-24 w-auto h-[240px]"
                />
                <div className="absolute top-[520px] flex justify-center items-center gap-8">
                  <img
                    src="./img/description.png"
                    alt="description"
                    className="h-[120px] w-auto mt-16"
                  />
                  <img
                    src="./img/register.png"
                    alt="register"
                    className="h-[280px] w-auto"
                  />
                </div>
                <div className="absolute top-[780px] flex justify-center items-center gap-16">
                  <img
                    src="./img/continue.png"
                    alt="continue"
                    className="h-[84px] w-auto mt-16"
                  />
                  <img
                    src="./img/settlement_options.png"
                    alt="settlement_options"
                    className="h-[280px] w-auto mt-[72px]"
                  />
                </div>
                <img
                  src="./img/advantages.png"
                  alt="advantages"
                  className="absolute top-[1200px] w-auto h-[256px]"
                />
              </div>
            </div>

            <div className="flex justify-center">
              <a
                href="/contact"
                className="absolute top-[1520px] bg-btn text-white font-bold py-3 px-8 rounded-full"
              >
                お問い合わせはこちら
              </a>
            </div>
            <div className="bg-white py-16">
              <div className="flex justify-center">
                <div className="text-3xl font-bold mb-8">
                  ISSUE PANDAを利用すると様々な施策を行うことができます！
                </div>
              </div>
              <div className="flex justify-center">
                <img
                  src="./img/point1.png"
                  alt="point1"
                  className="w-auto h-[400px]"
                />
              </div>
              <div className="flex justify-center">
                <img
                  src="./img/point2.png"
                  alt="point2"
                  className="w-auto h-[400px]"
                />
              </div>
            </div>
            <div className="bg-white py-16">
              <div className="flex justify-center">
                <div className="text-3xl font-bold mb-8">導入までの流れ</div>
              </div>
              <div className="flex justify-center">
                <img
                  src="./img/installation.jpg"
                  alt="installation"
                  className="w-auto h-[400px]"
                />
              </div>
              <div className="flex justify-center mt-12">
                <div className="text-3xl font-bold">
                  導入決定から約30日目で運用開始となります
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <a
                href="/contact"
                className="bg-btn text-white font-bold py-3 px-8 rounded-full"
              >
                お問い合わせはこちら
              </a>
            </div>
            <div className="flex justify-center mt-12">
              <div className="text-3xl font-bold">注意事項</div>
            </div>
            <div className="flex justify-center mt-4 mb-16">
              <div className="text-sm w-[40%] h-auto">
                お問い合わせいただきました、ご担当者様/店舗等の情報は、ISSUEPANDAのご提供に関する業務にのみ活用させていただきます
                詳しくは当社のプライバシーポリシーをご覧くださいませ。
                <br />
                ご導入後のお客様決済には、各種決済手段が取り決める手数料がかかります。
                決済手段ごとの決済手数料に関しては、担当営業にお問い合わせください。
                <br />
                ご導入に際するスケジュール等は、お店の運営状況により異なります。
                お問い合わせの上、ご調整させてくださいませ。
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
