import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useMediaQuery, mediaQuery } from "../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { getFooterHeight } from "../components/Footer";
import { useForm } from "react-hook-form";

export const VALIDATIONS = {
  name: {
    required: "お名前を入力してください",
    maxLength: {
      value: 50,
      message: "50文字以内で入力してください",
    },
  },
  email: {
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: "メールアドレスの形式で入力してください",
    },
  },
  phone: {
    pattern: {
      value: /^[0-9-]{1,20}$/,
      message: "電話番号の形式で入力してください",
    },
  },
  storeName: {
    maxLength: {
      value: 100,
      message: "100文字以内で入力してください",
    },
  },
  storeAddress: {
    maxLength: {
      value: 400,
      message: "400文字以内で入力してください",
    },
  },
  concern: {
    maxLength: {
      value: 1000,
      message: "1000文字以内で入力してください",
    },
  },
};

function Contact() {
  const isSp = useMediaQuery(mediaQuery.sp);
  const navigate = useNavigate();
  const footerHeight = getFooterHeight(isSp);
  const minHeight = window.innerHeight - footerHeight;

  const [isLoading, setIsLoading] = React.useState(false);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isValid },
  } = useForm({
    reValidateMode: "onSubmit",
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      storeName: "",
      storeAddress: "",
      concern: "",
    },
  });

  const sendRequest = async (formData) => {
    try {
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let bodyContent = JSON.stringify(formData);

      let response = await fetch("/api/contact/", {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      if (!response.ok) {
        return false;
      }

      return true;
    } catch (error) {
      console.log("error: ", error);
      return false;
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (data.email === "" && data.phone === "") {
      setError("email", {
        type: "manual",
        message: "メールアドレスまたは電話番号のどちらか必須です",
      });
      setIsLoading(false);
      return;
    }
    const result = await sendRequest(data);
    if (result) {
      navigate("/contact/completed");
    }
    setIsLoading(false);
  };

  return (
    <Box className="bg-gray-100 flex-col justify-between" minHeight={minHeight}>
      <div className="flex justify-center mb-8">
        <div className="text-3xl font-bold mt-8">お問い合わせ</div>
      </div>
      <Box sx={{ mx: "16px" }}>
        <Typography variant="body1" align="center">
          ISSUE PANDA導入のお問い合わせありがとうございます。
          <br />
          お問い合わせいただきましたらスタッフよりなるべく早くお返事いたします。
          <br />
          下記項目をご入力の上、ご連絡をお待ち下さい。
        </Typography>
      </Box>
      <div className="flex flex-col justify-center items-center mt-16 gap-8">
        <TextField
          label="ご担当者様お名前"
          variant="filled"
          className={isSp ? "w-[90%]" : "w-1/2"}
          color="primary"
          error={Boolean(errors.name)}
          helperText={errors.name ? errors.name.message : ""}
          required
          {...register("name", { ...VALIDATIONS.name })}
        />
        <TextField
          label="ご連絡先メールアドレス"
          variant="filled"
          className={isSp ? "w-[90%]" : "w-1/2"}
          color="primary"
          error={Boolean(errors.email)}
          helperText={
            errors.email
              ? errors.email.message
              : "※メールアドレスまたは電話番号のどちらか必須です"
          }
          {...register("email", { ...VALIDATIONS.email })}
        />
        <TextField
          label="ご連絡先電話番号(ハイフンなし)"
          variant="filled"
          className={isSp ? "w-[90%]" : "w-1/2"}
          color="primary"
          error={Boolean(errors.phone)}
          helperText={errors.phone ? errors.phone.message : ""}
          {...register("phone", { ...VALIDATIONS.phone })}
        />
        <TextField
          label="ご検討中の店舗様名"
          variant="filled"
          className={isSp ? "w-[90%]" : "w-1/2"}
          color="primary"
          error={Boolean(errors.storeName)}
          helperText={errors.storeName ? errors.storeName.message : ""}
          {...register("storeName", { ...VALIDATIONS.storeName })}
        />
        <TextField
          label="ご検討中の店舗住所"
          variant="filled"
          className={isSp ? "w-[90%]" : "w-1/2"}
          color="primary"
          error={Boolean(errors.storeAddress)}
          helperText={errors.storeAddress ? errors.storeAddress.message : ""}
          {...register("storeAddress", { ...VALIDATIONS.storeAddress })}
        />
        <TextField
          label="ISSUE PANDAについて特に気になることなどありましたらお伝え下さい"
          variant="filled"
          className={isSp ? "w-[90%]" : "w-1/2"}
          color="primary"
          error={Boolean(errors.concern)}
          helperText={errors.concern ? errors.concern.message : ""}
          multiline
          {...register("concern", { ...VALIDATIONS.concern })}
        />
      </div>
      {errors.check && <span>{errors.check.message}</span>}
      {isValid.check && <span>{isValid.check.message}</span>}
      <div className="flex justify-center py-16">
        <Button
          variant="contained"
          sx={{ borderRadius: 8, paddingX: 8 }}
          className="bg-btn py-3 px-8"
          onClick={handleSubmit(onSubmit)}
        >
          {isLoading ? (
            <CircularProgress size="1rem" sx={{ color: "white" }} />
          ) : (
            <Typography
              className="text-white"
              sx={{ fontWeight: "bold", fontSize: "1rem" }}
            >
              {"送信"}
            </Typography>
          )}
        </Button>
      </div>
    </Box>
  );
}

export default Contact;
