import { useMediaQuery, mediaQuery } from "../hooks/useMediaQuery";

export default function Footer() {
  const isSp = useMediaQuery(mediaQuery.sp);
  return isSp ? <SpFooter /> : <PcFooter />;
}

export const getFooterHeight = (isSp) => {
  return isSp ? 400 : 264;
};

export function SpFooter() {
  return (
    <footer className="bg-footer py-4 mt-8 h-[400px]">
      <div className="flex flex-col space-y-4">
        <a
          href="https://ex.swmp.place/issuepanda/kiyaku"
          className="hover:underline text-text font-bold"
        >
          利用規約
        </a>
        <a
          href="https://ex.swmp.place/issuepanda/policy"
          className="hover:underline text-text font-bold"
        >
          プライバシーポリシー
        </a>
        <a
          href="https://3bitter.com/"
          className="hover:underline text-text font-bold"
        >
          運営会社
        </a>
      </div>
      <div className="flex justify-center">
        <div className="border-t border-[#778899] border-opacity-30 mt-4 w-[100%] border-1"></div>
      </div>
      <div className="text-text font-bold text-sm mt-[32px]">
        手間はふやさず、決済を増やす
      </div>
      <div className="flex justify-center">
        <img src="./img/logo.png" alt="logo" className="w-auto h-[63px]" />
      </div>
      <div className="text-text font-bold text-sm mt-[32px]">3bitter inc.</div>
    </footer>
  );
}

export function PcFooter() {
  return (
    <footer className="bg-footer py-4 mt-8 h-[264px]">
      <div className="flex justify-center space-x-4 gap-8">
        <a
          href="https://ex.swmp.place/issuepanda/kiyaku"
          className="hover:underline text-text font-bold"
        >
          利用規約
        </a>
        <a
          href="https://ex.swmp.place/issuepanda/policy"
          className="hover:underline text-text font-bold"
        >
          プライバシーポリシー
        </a>
        <a
          href="https://3bitter.com/"
          className="hover:underline text-text font-bold"
        >
          運営会社
        </a>
      </div>
      <div className="flex justify-center">
        <div className="border-t border-[#778899] border-opacity-30 mt-4 w-[50%] border-1"></div>
      </div>
      <div className="text-text font-bold text-sm mt-[32px]">
        手間はふやさず、決済を増やす
      </div>
      <div className="flex justify-center">
        <img src="/img/logo.png" alt="logo" className="w-auto h-[63px]" />
      </div>
      <div className="text-text font-bold text-sm mt-[32px]">3bitter inc.</div>
    </footer>
  );
}
